/*
************
*********
******
****

DO NOT EDIT THIS FILE

Internal logic to connect panel metadata to components. 

****
******
********
************
*/

import CommunityConditions from '@components/community-conditions/community-conditions-panel';
import CommunityConditionsLearnMore from '@components/community-conditions/community-conditions-learn-more';
import IndicatorsPanel from '@components/view-indicators/indicators-panel';
import IndicatorsLearnMore from '@components/view-indicators/indicators-learn-more';
import PoolPanel from '@components/pool-geographies/pool-panel';
import DisplayPanel from '@components/edit-display/display-panel';
import EditDisplayLearnMore from '@components/edit-display/edit-display-learn-more';
import PoolLearnMore from '@components/pool-geographies/pool-learn-more';
import LifeExpectancyPanel from '@components/predict-life-expectancy/life-expectancy-panel';
import LifeExpectancyLearnMore from '@components/predict-life-expectancy/life-expectancy-learn-more';
import CompareIndicators from '@components/compare-indicators';
import UploadPanel from '@components/upload-data/upload-panel';
import CovidPanel from '@components/covid-panel';
import PoliciesPanel from '@components/policy-opportunities/policies-panel';
import CustomScorePanel from '@components/custom-score/custom-score-panel';
import CustomScoreLearnMore from '@components/custom-score/custom-score-learn-more';
import {
  faInfo,
  faHeart,
  faCompress,
  faBinoculars,
  faPalette,
  faEquals,
  faSortAmountDown,
  faUpload,
  faVirus,
  faSlidersH,
  faBalanceScale,
  faPencil,
} from '@fortawesome/pro-regular-svg-icons';
import RankPanel from '@components/rank-by-geography/rank-panel';
import FilterPanel from '@components/filter/filter-panel';
import RankLearnMore from '@components/rank-by-geography/rank-learn-more';
import { panelData, defaultPanel } from '@client/config/panelsConfig';

// more metadata about each panel
const allPanels = [
  {
    key: 'community-conditions',
    view: 'communityconditions',
    panelComponent: CommunityConditions,
    learnMoreComponent: CommunityConditionsLearnMore,
    icon: faInfo,
  },
  {
    key: 'covid',
    view: 'covid',
    panelComponent: CovidPanel,
    icon: faVirus,
  },
  {
    key: 'filter',
    view: 'filter',
    panelComponent: FilterPanel,
    icon: faSlidersH,
  },
  {
    key: 'view-indicators',
    view: 'indicators',
    panelComponent: IndicatorsPanel,
    learnMoreComponent: IndicatorsLearnMore,
    icon: faBinoculars,
  },
  {
    key: 'predict-life-expectancy',
    view: 'lifeexpectancy',
    panelComponent: LifeExpectancyPanel,
    learnMoreComponent: LifeExpectancyLearnMore,
    icon: faHeart,
  },
  {
    key: 'pool-geographies',
    view: 'pool',
    panelComponent: PoolPanel,
    learnMoreComponent: PoolLearnMore,
    icon: faCompress,
  },
  {
    key: 'edit-display',
    view: 'display',
    panelComponent: DisplayPanel,
    learnMoreComponent: EditDisplayLearnMore,
    icon: faPalette,
  },
  {
    key: 'compare-indicators',
    view: 'compare',
    panelComponent: CompareIndicators,
    icon: faEquals,
  },
  {
    key: 'rank-by-geography',
    view: 'rank',
    panelComponent: RankPanel,
    learnMoreComponent: RankLearnMore,
    icon: faSortAmountDown,
  },
  {
    key: 'upload-data',
    view: 'upload',
    panelComponent: UploadPanel,
    icon: faUpload,
  },
  {
    key: 'policy-opportunities',
    view: 'policy-opportunities',
    panelComponent: PoliciesPanel,
    icon: faBalanceScale,
  },

  {
    key: 'custom-score',
    view: 'custom-score',
    panelComponent: CustomScorePanel,
    learnMoreComponent: CustomScoreLearnMore,
    icon: faPencil,
  },
];

// export only the ones included in the config list
const panels = allPanels.reduce((combined, panel) => {
  const config = panelData.find(p => panel.key === p.key);
  if (config) {
    return [
      ...combined,
      {
        ...panel,
        ...config,
      },
    ];
  }
  return [...combined];
}, []);

// get data for default panels (from panels_to_include), if any
let defaultViewKey = null; // panel with default indicator selected
let defaultIndicatorViewKey = null; // panel with a different indicator selected
if (defaultPanel) {
  if (defaultPanel.defaultIndicatorSelected) {
    const defaultPanelData = panels.find(
      p => p.key === defaultPanel.defaultIndicatorSelected || p.title === defaultPanel.defaultIndicatorSelected
    );
    defaultViewKey = defaultPanelData ? defaultPanelData.view : null;
  }
  if (defaultPanel.otherIndicatorSelected) {
    const defaultPanelData = panels.find(
      p => p.key === defaultPanel.otherIndicatorSelected || p.title === defaultPanel.otherIndicatorSelected
    );
    defaultIndicatorViewKey = defaultPanelData ? defaultPanelData.view : defaultViewKey;
  } else {
    defaultIndicatorViewKey = defaultViewKey;
  }
}

export const defaultView = defaultViewKey;
export const defaultIndicatorView = defaultIndicatorViewKey;
export { defaultGeographyName, defaultIndicatorName, defaultClassification } from '@client/config/panelsConfig';

export default panels;
