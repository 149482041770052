import persistReducer from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import * as types from './main.actionType';
import {
  defaultView,
  defaultIndicatorView,
  defaultIndicatorName,
  defaultGeographyName,
  defaultClassification,
} from '@client/config/components/panels';
import {
  sequentialColors,
  divergingColors,
  percentileColors,
  valueColors,
  vulnerabilityColors,
} from '@client/config/panelsConfig';

const persistConfig = {
  key: 'main',
  storage,
  whitelist: [
    /* keys to be persisted */
  ],
};

const initIndicator = { name: 'hpi', id: 1 };
const percentileClassification = [
  { min: 0, max: 0.25, color: percentileColors[0] },
  { min: 0.25, max: 0.5, color: percentileColors[1] },
  { min: 0.5, max: 0.75, color: percentileColors[2] },
  { min: 0.75, max: 1, color: percentileColors[3] },
];
const valueClassification = [
  { ...percentileClassification[0], color: valueColors[0] },
  { ...percentileClassification[1], color: valueColors[1] },
  { ...percentileClassification[2], color: valueColors[2] },
  { ...percentileClassification[3], color: valueColors[3] },
];
const climateClassification = [
  { ...percentileClassification[0], color: sequentialColors[0] },
  { ...percentileClassification[1], color: sequentialColors[1] },
  { ...percentileClassification[2], color: sequentialColors[2] },
  { ...percentileClassification[3], color: sequentialColors[3] },
];
const hpiClassification = [
  { ...percentileClassification[0], color: divergingColors[0] },
  { ...percentileClassification[1], color: divergingColors[1] },
  { ...percentileClassification[2], color: divergingColors[2] },
  { ...percentileClassification[3], color: divergingColors[3] },
];

const initialState = {
  loading: false,
  // geography
  geographiesList: [],
  currentGeography: {
    name: 'Tracts',
    singular: 'Tract',
    id: 11,
    layer: 'tracts',
    tileUrl: 'https://tilecache.axismaps.io/hpi/tracts/{z}/{x}/{y}.pbf',
  },
  // indicator
  indicatorList: [],
  defaultIndicator: initIndicator,
  primaryIndicator: initIndicator,
  currentIndicator: initIndicator,
  currentIndicator2: null, // for split screen
  allIndicatorData: {},
  allPoolData: {},
  currentIndicatorData: [],
  currentPoolData: [],
  currentIndicatorData2: [], // for split screen
  currentPoolData2: [], // for split screen
  // choropleth
  attribute: 'percentile',
  classificationMethod: defaultClassification || 'Quartiles',
  defaultClassification,
  sequentialColors, // default colors
  divergingColors, // default colors
  percentileClassification,
  valueClassification,
  climateClassification,
  hpiClassification,
  choroplethClassification:
    (defaultClassification || 'Quartiles').toLowerCase() === 'quartiles' ? percentileClassification : valueClassification,
  choroplethClassification2: percentileClassification, // for split screen
  choroplethFilter: [0, 100], // filter by current indicator
  indicatorFilter: {}, // filter by other indicators
  // highlight/selection
  highlightedFeature: null,
  selectedFeature: null,
  // ui views
  currentView: null,
  toolsMenuActive: false,
  activeChanges: [],
  // feature data
  featureConditionData: {},
  featureMetadata: {},
  featureRaceEthnicityData: {},
  // pools
  pools: [],
  activePool: {},
  // search,
  searchTerm: '',
  searchResults: null,
  // rank
  rankData: null,
  // user
  isLoggedIn: false,
  username: null,
  loginError: null,
  // saved views
  savedViews: [],
  // import
  layers: [],
  currentLayerImport: null,
  importError: null,
  importLoading: false,
  currentUserLayer: null,
  currentOverlayGeoJSON: undefined,
  // policies panel
  policyOpportunities: [],
  defaultPolicies: undefined,
  // custom score
  customScore: {
    domains: [],
    indicators: [],
  },
  multipleVulnerabilities: [],
};

const addIndicatorData = (currentData, geographies) => {
  if (!geographies) return currentData; // not adding data; this happens when re-loading a geography/indicator
  // appends incoming indicator data
  const dataCopy = { ...currentData };
  Object.entries(geographies).forEach(([, newData]) => {
    const { geography, indicator, data } = newData;
    if (!dataCopy[geography]) dataCopy[geography] = {};
    if (!dataCopy[geography][indicator]) dataCopy[geography][indicator] = [];
    dataCopy[geography][indicator] = [...dataCopy[geography][indicator], ...data];
  });

  return dataCopy;
};

const getIndicator = (id, indicatorList, layers) => {
  let currentIndicator = indicatorList.reduce((flat, group) => [...flat, ...group.Indicators], []).find(i => i.id === id);
  if (!currentIndicator) currentIndicator = layers.find(l => l.id === id);
  return currentIndicator; // undefined if not found in either list
};

const search = (searchTerm, geographies) => {
  if (!searchTerm || searchTerm.length < 2) return null;
  const reg = new RegExp(searchTerm, 'gi');
  const results = geographies
    .filter(g => g.features)
    .map(g => ({
      ...g,
      results: g.features
        .filter(f => f.name && f.name.match(reg))
        .sort((a, b) => {
          if (a.name.search(reg) < b.name.search(reg)) return -1;
          if (b.name.search(reg) < a.name.search(reg)) return 1;
          return a.name.localeCompare(b.name);
        }),
    }))
    .filter(g => g.results.length > 0);
  return results;
};

/*
 * containers/main/reducer.js : main reducer
 * @param {Object} state the state of the main container
 * @param {Object} action the redux action instance
 * @returns {Object} returns the new state
 */
function main(state = initialState, action) {
  // NOSONAR
  switch (action.type) {
    /**
     * Data loading */

    case types.MAIN_LOAD_INDICATOR_LIST_SUCCESS: {
      const indicatorList = action.payload.response.map(cat => ({
        ...cat,
        Indicators: cat.Indicators.map(ind => {
          const { percentile, ...props } = ind;
          return {
            ...props,
            domain: {
              id: cat.id,
              name: cat.name,
              weight: cat.weight,
            },
            mapPercentile: percentile,
          };
        }),
      }));
      const indicator =
        indicatorList
          .reduce((flat, group) => [...flat, ...group.Indicators], [])
          .find(i => i.title === defaultIndicatorName) ||
        (action.payload.response.find(g => g.name === 'Primary') || action.payload.response[0]).Indicators[0];
      return {
        ...state,
        indicatorList,
        defaultIndicator: indicator,
        currentIndicator: indicator,
        primaryIndicator: (action.payload.response.find(g => g.name === 'Primary') || action.payload.response[0])
          .Indicators[0],
        customScore: {
          domains: [],
          indicators: indicatorList
            .filter(d => d.weight > 0)
            .reduce((flat, domain) => [...flat, ...domain.Indicators], [])
            .map(i => i.id),
        },
      };
    }

    case types.MAIN_LOAD_GEOGRAPHIES_LIST_SUCCESS: {
      return {
        ...state,
        geographiesList: action.payload.response,
        currentGeography:
          action.payload.response.find(i => i.name === defaultGeographyName) ||
          action.payload.response.find(g => g.default === true),
        allIndicatorData: action.payload.response.reduce((obj, geog) => ({ ...obj, [geog.id]: {} }), {}),
      };
    }

    case types.MAIN_GEOGRAPHIES_STATS_SUCCESS: {
      const { stats, geography } = action.payload;
      return {
        ...state,
        geographiesList: state.geographiesList.map(g => {
          if (geography === g.id) {
            return {
              ...g,
              stats,
            };
          }
          return g;
        }),
        currentGeography: { ...state.currentGeography, stats },
      };
    }

    case types.MAIN_LOAD_INDICATOR_DATA:
      return {
        ...state,
        loading: true,
      };

    case types.MAIN_LOAD_INDICATOR_DATA_SUCCESS: {
      // this will happen when clearing split screen map, because I don't know how to "do nothing" w/ Redux observable
      if (!action.payload) return state;

      const { which, dataOnly } = action.payload; // dataOnly = do not set this as current indicator
      const { defaultIndicator } = state;

      if (!action.payload.isPools) {
        let whichCurrentIndicatorData = 'currentIndicatorData';
        let whichCurrentIndicator = 'currentIndicator';
        let whichChoroplethClassification = 'choroplethClassification';
        if (which === 2) {
          whichCurrentIndicatorData = 'currentIndicatorData2';
          whichCurrentIndicator = 'currentIndicator2';
          whichChoroplethClassification = 'choroplethClassification2';
        }
        const newData = addIndicatorData(
          state.allIndicatorData,
          action.payload.geographies // <- contains one or more data objects, i.e. current geography and/or state level
        );

        const { rankData } = state;
        // rescaled ranks take priority over regular data
        const newCurrentIndicatorData =
          rankData && rankData.data && rankData.data.rescale
            ? rankData.ranks
            : newData[action.payload.geography][action.payload.indicator];
        if (!dataOnly) {
          return {
            ...state,
            loading: false,
            allIndicatorData: newData,
            [whichCurrentIndicatorData]: newCurrentIndicatorData,
            [whichCurrentIndicator]:
              getIndicator(action.payload.indicator, state.indicatorList, state.layers) || defaultIndicator,
            currentGeography: state.geographiesList.find(g => g.id === action.payload.geography),
            // reset to blue-green colors if this is default HPI indicator
            [whichChoroplethClassification]:
              action.payload.indicator === defaultIndicator.id && defaultIndicator.mapPercentile
                ? state.choroplethClassification.map((c, i) => ({
                    ...c,
                    color: percentileClassification[i].color,
                  }))
                : state[whichChoroplethClassification],
          };
        }
        return {
          ...state,
          loading: false,
          allIndicatorData: newData,
        };
      }
      const newData = addIndicatorData(state.allPoolData, action.payload.geographies);
      const whichCurrentPoolData = which === 2 ? 'currentPoolData2' : 'currentPoolData';
      return {
        ...state,
        loading: false,
        allPoolData: newData,
        [whichCurrentPoolData]: newData[action.payload.geography][action.payload.indicator],
      };
    }

    case types.MAIN_HISTOGRAM_DATA_SUCCESS: {
      const indicators = [...state.indicatorList];
      const indicator = getIndicator(action.payload.indicator, state.indicatorList, state.layers);
      if (!indicator.histograms) indicator.histograms = {};
      indicator.histograms[action.payload.geography] = action.payload.data;
      return {
        ...state,
        indicatorList: indicators,
        currentIndicator:
          state.currentIndicator && state.currentIndicator.id === action.payload.indicator
            ? indicator
            : state.currentIndicator,
      };
    }

    case types.MAIN_SET_INDICATOR: {
      const { indicator, which } = action.payload;
      const { defaultIndicator, choroplethClassification } = state;
      // clear split screen
      if (which === 2 && indicator === null) {
        return {
          ...state,
          currentIndicator2: null,
          currentIndicatorData2: [],
          currentPoolData2: [],
        };
      }
      const id = indicator || defaultIndicator.id;
      const whichCurrentIndicator = which === 2 ? 'currentIndicator2' : 'currentIndicator';
      const whichChoroplethClassification = which === 2 ? 'choroplethClassification2' : 'choroplethClassification';
      const currentIndicator = getIndicator(id, state.indicatorList, state.layers) || defaultIndicator;
      const { domain } = currentIndicator;
      let classification;
      if (!currentIndicator.mapPercentile) {
        classification = [
          { ...choroplethClassification[0], color: '#ecffff' },
          { ...choroplethClassification[1], color: '#b3c7e3' },
          { ...choroplethClassification[2], color: '#7a93c7' },
          { ...choroplethClassification[3], color: '#3662ab' },
        ];
      } else if (domain.name === 'County COVID-19 Counts') {
        classification = [
          { min: 0, max: 0.25, color: 'rgb(0, 136, 55)' },
          { min: 0.25, max: 0.5, color: 'rgb(166, 219, 160)' },
          { min: 0.5, max: 0.75, color: 'rgb(158, 202, 225)' },
          { min: 0.75, max: 1, color: 'rgb(33, 113, 181)' },
        ];
      } else {
        classification = [
          { min: 0, max: 0.25, color: 'rgb(33, 113, 181)' },
          { min: 0.25, max: 0.5, color: 'rgb(158, 202, 225)' },
          { min: 0.5, max: 0.75, color: 'rgb(166, 219, 160)' },
          { min: 0.75, max: 1, color: 'rgb(0, 136, 55)' },
        ];
      }
      // clear currentUserLayer if switching away from a csv layer here
      const currentUserLayer =
        state.currentUserLayer && state.currentUserLayer.type === 'csv' && !currentIndicator.layerId
          ? null
          : state.currentUserLayer;
      const newClassification = currentIndicator.mapPercentile ? 'Quartiles' : 'Equal Interval';
      return {
        ...state,
        customScore: {
          domains: [],
          indicators: state.indicatorList
            .filter(d => d.weight > 0)
            .reduce((flat, d) => [...flat, ...d.Indicators], [])
            .map(i => i.id),
        },
        activeChanges: state.activeChanges.filter(c => c.key !== 'custom-score' && c.key !== 'edit-display'),
        classificationMethod: newClassification,
        [whichChoroplethClassification]: classification,
        [whichCurrentIndicator]: currentIndicator,
        currentUserLayer,
        indicatorFilter: {},
      };
    }

    case types.MAIN_SET_GEOGRAPHY: {
      const newGeog = state.geographiesList.find(g => g.id === action.payload);
      const newRankData = state.rankData && action.payload !== state.rankData.data.geography ? null : state.rankData;
      return {
        ...state,
        currentGeography: newGeog,
        // deselect feature if switching geography
        selectedFeature: newGeog.id === state.currentGeography.id ? state.selectedFeature : null,
        activePool: {},
        pools: [],
        rankData: newRankData,
        indicatorFilter: {},
      };
    }
    /**
     * Highlight/select feature */

    case types.MAIN_HIGHLIGHT_FEATURE: {
      return {
        ...state,
        highlightedFeature: action.payload,
      };
    }

    case types.MAIN_SELECT_FEATURE: {
      const { defaultIndicator } = state;
      let view = state.currentView;
      // open details panel on feature selection, unless certain panels are open
      const viewsNotToChange = ['life-expectancy']; // and others?
      if (action.payload !== null && !viewsNotToChange.includes(view)) {
        if (state.currentIndicatorData2 && state.currentIndicatorData2.length) view = 'compare';
        // ^ open compare panel if in split screen
        else
          view =
            state.currentIndicator.id === defaultIndicator.id || state.currentIndicator.title === 'Custom Score'
              ? defaultView
              : defaultIndicatorView;
      }

      return {
        ...state,
        selectedFeature: action.payload,
        currentView: view,
      };
    }

    /**
     * Map classification and filter */

    case types.MAIN_SET_ATTRIBUTE: {
      return {
        ...state,
        attribute: action.payload,
      };
    }

    case types.MAIN_SET_CLASSIFICATION_METHOD: {
      return {
        ...state,
        classificationMethod: action.payload,
      };
    }

    case types.MAIN_SET_CLASSIFICATION: {
      const { classification, which } = action.payload;
      const whichChoroplethClassification = which === 2 ? 'choroplethClassification2' : 'choroplethClassification';
      return {
        ...state,
        [whichChoroplethClassification]: classification,
      };
    }

    case types.MAIN_SET_CHOROPLETH_FILTER: {
      const { choroplethClassification } = state;
      return {
        ...state,
        choroplethFilter: action.payload || [
          choroplethClassification[0].min,
          choroplethClassification[choroplethClassification.length - 1].max,
        ],
      };
    }

    case types.MAIN_SET_INDICATOR_FILTER: {
      // action only does anything if removing all filters, otherwise gets kicked to 'success' action below
      if (!action.payload) {
        return {
          ...state,
          indicatorFilter: {},
        };
      }
      return {
        ...state,
        loading: true, // setting filter may involve loading an indicator
      };
    }

    case types.MAIN_SET_INDICATOR_FILTER_SUCCESS: {
      if (!action.payload) {
        return state;
      }
      const { indicatorFilter } = state;
      const { indicator, values } = action.payload;
      const filterCopy = { ...indicatorFilter };
      if (!values && filterCopy[indicator]) {
        delete filterCopy[indicator];
      } else if (values) {
        filterCopy[indicator] = values;
      }
      return {
        ...state,
        indicatorFilter: filterCopy,
        loading: false,
      };
    }

    /**
     * Probe */

    case types.MAIN_SET_PROBE_DATA: {
      return {
        ...state,
        probeData: action.payload,
      };
    }

    /**
     * Search */

    case types.MAIN_SET_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.payload,
        searchResults: search(action.payload, state.geographiesList),
      };
    }

    /**
     * Active panel */

    case types.MAIN_SET_VIEW: {
      return {
        ...state,
        currentView: action.payload,
        toolsMenuActive: false,
        selectedFeature: action.payload ? state.selectedFeature : null,
      };
    }

    case types.MAIN_TOGGLE_TOOLS: {
      return {
        ...state,
        toolsMenuActive: action.payload,
      };
    }

    case types.MAIN_SET_PANEL_CHANGED: {
      const { activeChanges } = state;
      const currentChangeState = activeChanges.find(p => p.key === action.payload.panel.key);
      if (action.payload.changed) {
        if (currentChangeState) return state;
        return {
          ...state,
          activeChanges: [...activeChanges, { ...action.payload.panel, onClear: action.payload.onClear }],
        };
      }
      if (!currentChangeState) return state;
      return {
        ...state,
        activeChanges: activeChanges.filter(p => p.key !== currentChangeState.key),
      };
    }

    /**
     * Feature data */

    case types.MAIN_FEATURE_CONDITION_DATA_SUCCESS: {
      if (!action.payload.data) return state;
      return {
        ...state,
        featureConditionData: {
          ...state.featureConditionData,
          [action.payload.geoid]: action.payload.data,
        },
      };
    }

    case types.MAIN_FEATURE_METADATA_SUCCESS: {
      if (!action.payload.data) return state;
      return {
        ...state,
        featureMetadata: {
          ...state.featureMetadata,
          [action.payload.geoid]: action.payload.data,
        },
      };
    }

    case types.MAIN_RACE_DATA_SUCCESS: {
      if (!action.payload.data) return state;
      return {
        ...state,
        featureRaceEthnicityData: {
          ...state.featureRaceEthnicityData,
          [action.payload.geoid]: action.payload.data.find(c => c.name === 'Race/Ethnicity').indicators,
        },
      };
    }

    /**
     * Pools */
    case types.MAIN_ADD_TO_POOL: {
      if (Array.isArray(action.payload)) {
        const newPool = action.payload.reduce((pools, thisPool) => {
          return { ...pools, [thisPool.geoid]: thisPool.name };
        }, state.activePool);

        return {
          ...state,
          activePool: newPool,
        };
      }
      return {
        ...state,
        activePool: { ...state.activePool, [action.payload.geoid]: action.payload.name },
      };
    }

    case types.MAIN_REMOVE_FROM_POOL: {
      const pool = { ...state.activePool };
      if (pool[action.payload.geoid] !== undefined) delete pool[action.payload.geoid];
      return {
        ...state,
        activePool: pool,
      };
    }

    case types.MAIN_CLEAR_ACTIVE_POOL: {
      return {
        ...state,
        activePool: {},
      };
    }

    case types.MAIN_CREATE_POOL: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.MAIN_POOL_SUCCESS: {
      const { entities, geojson } = action.payload;
      const names = Object.values(entities);
      const name = names.length > 2 ? `${names[0]} + ${names.length - 1} more` : names.slice(0, 2).join(' and ');
      return {
        ...state,
        loading: false,
        pools: [
          ...state.pools,
          {
            entities,
            name,
            geoid: geojson.features[0].properties.geoid,
            geojson: {
              ...geojson,
              features: geojson.features.map(feature => ({
                ...feature,
                id: Date.now(),
                properties: {
                  ...feature.properties,
                  pool: true,
                  name,
                },
              })),
            },
          },
        ],
        activePool: {},
      };
    }

    case types.MAIN_DELETE_POOL: {
      // delete any pool containing passed geoid, on assumption it would only be in one
      // if no geoid, clears all pools
      return {
        ...state,
        pools: action.payload ? state.pools.filter(p => p.entities[action.payload] === undefined) : [],
      };
    }

    /**
     * Rank */

    case types.MAIN_GET_RANK_DATA: {
      // remove ranks from "active changes" if clearning, since this can happen when the panel isn't open
      const newActive = action.payload ? state.activeChanges : state.activeChanges.filter(d => d.view !== 'rank');
      return {
        ...state,
        rankData: action.payload ? { data: action.payload, ranks: [] } : null,
        loading: true,
        activeChanges: newActive,
      };
    }

    case types.MAIN_RANK_DATA_SUCCESS: {
      const rankData = action.payload;
      const { allIndicatorData, currentIndicator, allPoolData, currentGeography } = state;

      // rank data can force a change in geography, but this is handled in rankEpic

      // if not rescaled, current data is the basic geography/indicator set, but it may not exist (yet) if geography change was involved
      const indicatorData = allIndicatorData[currentGeography.id]
        ? allIndicatorData[currentGeography.id][currentIndicator.id]
        : [];
      const poolData = allPoolData[currentGeography.id] ? allPoolData[currentGeography.id][currentIndicator.id] : [];

      if (rankData) {
        return {
          ...state,
          rankData,
          currentIndicatorData: rankData.data.rescale ? rankData.ranks : indicatorData,
          currentPoolData: rankData.data.rescale ? [] : poolData,
          loading: false,
        };
      }
      return {
        ...state,
        rankData,
        currentIndicatorData: indicatorData,
        currentPoolData: poolData,
        loading: false,
      };
    }

    /**
     * Login */

    case types.MAIN_LOGIN_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
        username: action.payload.username,
      };
    }

    case types.MAIN_LOGIN_ERROR: {
      return {
        ...state,
        loginError: action.payload,
      };
    }

    case types.MAIN_LOGOUT_SUCCESS: {
      return {
        ...state,
        isLoggedIn: false,
        username: null,
        savedViews: [],
      };
    }

    /**
     * Saved views */

    case types.MAIN_RECEIVED_VIEWS: {
      return {
        ...state,
        savedViews: action.payload,
      };
    }

    case types.MAIN_LOAD_SAVED_VIEW: {
      const { defaultIndicator } = state;
      return {
        ...state,
        currentIndicator: getIndicator(action.payload.indicator, state.indicatorList, state.layers) || defaultIndicator,
        currentGeography: state.geographiesList.find(g => g.id === action.payload.geography),
      };
    }

    /**
     * Import */

    case types.MAIN_LAYERS_SUCCESS: {
      return {
        ...state,
        layers: action.payload,
      };
    }

    case types.MAIN_UPLOAD_FILE: {
      return {
        ...state,
        importLoading: true,
      };
    }

    case types.MAIN_UPLOAD_SUCCESS: {
      return {
        ...state,
        currentLayerImport: action.payload,
        importLoading: false,
      };
    }

    case types.MAIN_UPLOAD_ERROR: {
      return {
        ...state,
        importError: action.payload,
        importLoading: false,
      };
    }

    case types.MAIN_SAVE_LAYER: {
      return {
        ...state,
        importLoading: true,
      };
    }

    case types.MAIN_SAVE_LAYER_SUCCESS: {
      return {
        ...state,
        importError: null,
        currentLayerImport: null,
        importLoading: false,
      };
    }

    case types.MAIN_SAVE_LAYER_ERROR: {
      return {
        ...state,
        importError: action.payload,
        importLoading: false,
      };
    }

    case types.MAIN_LOAD_LAYER_DATA: {
      return {
        ...state,
        currentIndicator: action.payload.type === 'csv' ? action.payload : state.currentIndicator,
        currentUserLayer: action.payload,
        loading: true,
      };
    }

    case types.MAIN_SET_OVERLAY_LAYER: {
      return {
        ...state,
        currentOverlayGeoJSON: action.payload || undefined,
        loading: false,
      };
    }

    case types.MAIN_SET_CURRENT_USER_LAYER: {
      return {
        ...state,
        currentUserLayer: action.payload,
      };
    }

    /**
     * Export */
    case types.MAIN_EXPORT_MAP: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.EXPORT_MAP_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    /**
     * Policy panel */
    case types.MAIN_GET_POLICIES: {
      return {
        ...state,
        policyOpportunities: [],
      };
    }

    case types.MAIN_POLICIES_SUCCESS: {
      const policyOpportunities = Object.entries(action.payload.policies).map(([domain, items]) => {
        return {
          domain,
          guides: items,
        };
      });

      if (!action.payload.geoid) {
        return {
          ...state,
          policyOpportunities,
          defaultPolicies: action.payload.policies,
        };
      }
      return {
        ...state,
        policyOpportunities,
      };
    }

    case types.MAIN_SET_CUSTOM_SCORE: {
      const hpiIndicators = state.indicatorList
        .filter(d => d.weight > 0)
        .reduce((flat, domain) => [...flat, ...domain.Indicators], [])
        .map(i => i.id);
      if (!action.payload) {
        return {
          ...state,
          customScore: {
            domains: [],
            indicators: hpiIndicators,
          },
          currentIndicator: state.defaultIndicator,
          loading: true,
        };
      }
      return {
        ...state,
        customScore: action.payload,
        loading: true,
      };
    }

    case types.MAIN_CUSTOM_SCORE_SUCCESS: {
      if (action.payload) {
        const newData = addIndicatorData(
          state.allIndicatorData,
          action.payload.data
            ? [
                {
                  geography: state.currentGeography.id,
                  indicator: action.payload.id,
                  data: action.payload.data,
                },
              ]
            : null
        );
        return {
          ...state,
          allIndicatorData: newData,
          currentIndicator: {
            id: action.payload.id,
            title: 'Custom Score',
            mapPercentile: true,
          },
          currentIndicatorData: action.payload.data || state.allIndicatorData[state.currentGeography.id][action.payload.id],
          loading: false,
        };
      }
      return {
        ...state,
        currentIndicator: state.defaultIndicator,
        currentIndicatorData: state.allIndicatorData[state.currentGeography.id][state.defaultIndicator.id],
        loading: false,
      };
    }

    case types.MAIN_SET_MULTIPLE_VULNERABILITIES: {
      if (!action.payload) {
        return {
          ...state,
          multipleVulnerabilities: [],
        };
      }
      return {
        ...state,
        multipleVulnerabilities: action.payload,
      };
    }

    case types.MAIN_MULTIPLE_VULNERABILITIES_SUCCESS: {
      const { multipleVulnerabilities } = state;
      let classification = vulnerabilityColors.map(c => ({ min: Infinity, max: Infinity, color: c }));
      if (multipleVulnerabilities.length === 1) {
        classification[0].min = 0;
        classification[0].max = 1;
        classification[3].min = 1;
      } else if (multipleVulnerabilities.length === 2) {
        classification[0].min = 0;
        classification[0].max = 1;
        classification[1].min = 1;
        classification[1].max = 2;
        classification[3].min = 2;
      } else {
        classification = classification.map((c, i) => ({
          ...c,
          min: (i * multipleVulnerabilities.length) / 4,
          max: ((i + 1) * multipleVulnerabilities.length) / 4,
        }));
      }
      return {
        ...state,
        currentIndicator: { id: 'vulnerability', title: 'Multiple Vulnerabilities' },
        currentIndicatorData: action.payload,
        attribute: 'value',
        classificationMethod: 'vulnerability',
        choroplethClassification: classification,
        choroplethFilter: [classification[0].min, classification[classification.length - 1].max],
      };
    }

    /**
     * Test */

    case types.MAIN_TEST:
      return {
        ...state,
        loading: true,
      };

    case types.MAIN_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };

    case types.MAIN_TEST_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}

export default persistReducer(persistConfig, main);
