//  map-container.epic.js
//  Project hpi
//
//  Created by Axis Maps on 4/7/2020, 9:30:49 AM
//

import * as types from './map-container.actionType';
import * as actions from './map-container.action';

export const geojsonEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAPCONTAINER_GET_GEOJSON)
    .mergeMap(action => {
      if (!action.payload) return of(actions.testError());
      // const { mapcontainer } = store.value;
      const { geoid, geography, type } = action.payload;

      // TO DO? skip if geojson already exists (action shouldn't occur in the first place, but you never know)

      return ajax({
        url: `/api/v1/geojson/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ geoid, geography }),
      })
        .mergeMap(response => {
          return of(actions.geojsonSuccess({ geoid, geography, type, data: response.response.response }));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export default (action$, store, { of }) =>
  action$.ofType(types.MAPCONTAINER_TEST).mergeMap(action => of(actions.testSuccess(action.payload)));
