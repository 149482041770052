import * as types from './main.actionType';
import * as actions from './main.action';
import { setViewport } from '@components/map-components/map-container/map-container.action';
import { extent } from 'd3';
import { ckmeans, mean } from 'simple-statistics';

const getUrlParam = name => {
  const url = new URL(window.location.href);
  return url.searchParams.get(name);
};

const getCurrentStateData = (main, which = 1) => {
  const { currentIndicator, currentIndicator2, allIndicatorData, geographiesList } = main;
  const state = geographiesList.find(g => g.name === 'State');
  if (!state) return null;
  if (allIndicatorData[state.id]) {
    return allIndicatorData[state.id][which === 1 ? currentIndicator.id : currentIndicator2.id];
  }
  return null;
};

const getClassificationData = (data, stateData = [{}], method, currentAttribute) => {
  if (!data || !data.length) {
    return {
      breakVals: [
        [0, 0.25],
        [0.25, 0.5],
        [0.5, 0.75],
        [0.75, 1],
      ],
      attribute: currentAttribute,
    };
  }
  let attribute;
  const m = method.toLowerCase();
  const vals = data.map(d => d.value);
  const [min, max] = extent(data, d => d.value);
  // stateData will be an array of one data point
  let sData = stateData;
  if (!stateData.length) sData = [{}]; // an empty array can sometimes from from the API
  const avg = sData[0].value || mean(vals); // fall back on mean of current geog, if no state value
  let breaks;
  if (m === 'quartiles') {
    breaks = [0, 0.25, 0.5, 0.75, 1];
    attribute = 'percentile';
  }
  // non-percentile methods always use state mean as the central break
  else if (m === 'natural breaks') {
    const aboveBelowMean = vals.reduce(
      (memo, val) => {
        if (val === null) return memo;
        if (val >= avg) {
          return [memo[0], [...memo[1], val]];
        }
        return [[...memo[0], val], memo[1]];
      },
      [[], []]
    );
    // get two-class natural breaks above and below mean
    const binsBelow = ckmeans(aboveBelowMean[0], 2).map(b => [b[0], b[b.length - 1]]);
    const binsAbove = ckmeans(aboveBelowMean[1], 2).map(b => [b[0], b[b.length - 1]]);
    breaks = [min, binsBelow[1][0], avg, binsAbove[1][0], max];
    attribute = 'value';
  } else {
    // "equal" interval
    breaks = [min, min + (avg - min) / 2, avg, avg + (max - avg) / 2, max];
    attribute = 'value';
  }
  const breakVals = breaks.slice(0, 4).map((b, i) => [b, breaks[i + 1]]);
  return { breakVals, attribute };
};

// sets up a series of actions to occur in sequence

export const indicatorEpic = (action$, store, { getJSON, of }) => {
  return action$.ofType(types.MAIN_LOAD_INDICATOR_LIST).mergeMap(() =>
    // to do: check if already loaded
    getJSON(`/api/v1/indicators`)
      .map(response => actions.loadIndicatorListSuccess(response))
      .catch(error => of(actions.testError(error)))
  );
};

export const indicatorSuccessEpic = (action$, store, { of }) => {
  return action$.ofType(types.MAIN_LOAD_INDICATOR_LIST_SUCCESS).mergeMap(() => of(actions.loadGeographiesList()));
};

export const geographiesEpic = (action$, store, { getJSON, of }) => {
  return action$.ofType(types.MAIN_LOAD_GEOGRAPHIES_LIST).mergeMap(() =>
    // to do: check if already loaded
    getJSON(`/api/v1/geographies`)
      .map(response => actions.loadGeographiesListSuccess(response))
      .catch(error => of(actions.testError(error)))
  );
};

export const geographiesListSuccessEpic = (action$, store, { of }) => {
  return action$.ofType(types.MAIN_LOAD_GEOGRAPHIES_LIST_SUCCESS).mergeMap(() => {
    const { main } = store.value;
    const { currentGeography } = main;
    const savedView = getUrlParam('view');
    if (savedView) {
      return of(actions.getViewById(savedView));
    }
    return of(actions.setGeography(currentGeography.id));
  });
};

export const setGeographyEpic = (action$, store, { of, ajax }) => {
  return action$.ofType(types.MAIN_SET_GEOGRAPHY).mergeMap(() => {
    const { main } = store.value;
    const { indicatorList, currentIndicator, currentIndicator2, currentGeography, customScore } = main;

    const hpiIndicators = indicatorList
      .filter(d => d.weight > 0)
      .reduce((flat, domain) => [...flat, ...domain.Indicators], [])
      .map(i => i.id);
    const isCustomScore = customScore.domains.length || hpiIndicators.some(i => customScore.indicators.indexOf(i) === -1);
    const isVulnerability = main.multipleVulnerabilities && main.multipleVulnerabilities.length;
    if (!currentGeography.stats) {
      return ajax({
        url: `/api/v1/stats/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ geography: currentGeography.id }),
      })
        .mergeMap(response => {
          let dataAction;
          if (isCustomScore) dataAction = actions.setCustomScore(customScore);
          else if (isVulnerability) dataAction = actions.setMultipleVulnerabilities(main.multipleVulnerabilities);
          else dataAction = actions.loadIndicatorData({ indicator: currentIndicator.id, geography: currentGeography.id });
          const actionsToDo = [
            actions.geographiesStatsSuccess({ geography: currentGeography.id, stats: response.response.response }),
            dataAction,
          ];
          if (currentIndicator2)
            actionsToDo.push(
              actions.loadIndicatorData({ indicator: currentIndicator2.id, geography: currentGeography.id, which: 2 })
            );
          return of(...actionsToDo);
        })
        .catch(error => of(actions.testError(error)));
    }
    let dataAction;
    if (isCustomScore) dataAction = actions.setCustomScore(customScore);
    else if (isVulnerability) dataAction = actions.setMultipleVulnerabilities(main.multipleVulnerabilities);
    else dataAction = actions.loadIndicatorData({ indicator: currentIndicator.id, geography: currentGeography.id });
    const actionsToDo = [dataAction];
    if (currentIndicator2)
      actionsToDo.push(
        actions.loadIndicatorData({ indicator: currentIndicator2.id, geography: currentGeography.id, which: 2 })
      );
    return of(...actionsToDo);
  });
};

export const indicatorFilterEpic = (action$, store, { of, ajax, forkJoin }) => {
  return action$.ofType(types.MAIN_SET_INDICATOR_FILTER).mergeMap(action => {
    const { main } = store.value;
    if (!action.payload || !action.payload.values) {
      return of(actions.setIndicatorFilterSuccess(action.payload));
    }
    const { indicator } = action.payload;
    const { currentGeography, geographiesList, allIndicatorData } = main;
    const state = geographiesList.find(g => g.name === 'State');
    const body = {
      indicator,
      geography: currentGeography.id,
      attributes: ['value', 'percentile'],
    };

    // get the data request functions
    const fetchMainData = () => {
      return {
        body,
        req: ajax({
          url: `/api/v1/indicator/`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        }),
      };
    };
    const fetchStateData = () => {
      const stateBody = {
        indicator: body.indicator,
        geography: state.id,
        attributes: ['value', 'percentile'],
      };
      return {
        body: stateBody,
        req: ajax({
          url: `/api/v1/indicator/`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(stateBody),
        }),
      };
    };

    // check if data already exists for the main geography (e.g. tracts)
    let mainDataLoaded = false;
    const existingData = allIndicatorData;
    if (existingData && existingData[body.geography] && existingData[body.geography][body.indicator]) {
      // data already exists
      mainDataLoaded = true;
    }

    // check if state-level data exists
    const stateDataLoaded = state && existingData[state.id] && existingData[state.id][body.indicator];

    // request main (e.g. tracts or pools) data and/or state data as needed
    const requests = [];
    if (!mainDataLoaded) requests.push(fetchMainData());
    // also skip state data if this is a pool request
    if (!stateDataLoaded) requests.push(fetchStateData());

    const actionsToDo = [];

    if (!requests.length) {
      // there's truly no new data to load
      actionsToDo.push(actions.setIndicatorFilterSuccess(action.payload));
      return of(...actionsToDo);
    }

    return forkJoin(...requests.map(r => r.req))
      .mergeMap(res => {
        // data for each geography requested (possibly two, including state level)
        const successData = res.reduce((combined, r, i) => {
          const reqBody = requests[i].body;
          return {
            ...combined,
            [reqBody.geography]: {
              ...reqBody,
              isPools: action.payload.isPools,
              data: r.response.response,
            },
          };
        }, {});
        actionsToDo.push(
          actions.loadIndicatorDataSuccess({
            // send back the indicator/geog actually requested (e.g. tracts) for clarity
            ...body,
            geographies: successData, // contains the actual data (state and/or e.g. tracts)
            dataOnly: true,
          }),
          actions.setIndicatorFilterSuccess(action.payload)
        );
        return of(...actionsToDo);
      })
      .catch(error => of(actions.testError(error)));
  });
};

export const setIndicatorEpic = (action$, store, { of }) => {
  return action$.ofType(types.MAIN_SET_INDICATOR).mergeMap(action => {
    const { main } = store.value;
    // `which` is to specify indicator 1 or 2 in split screen (default 1 when undefined in other modes)
    const { indicator, which } = action.payload;
    // clear split screen
    if (which === 2 && indicator === null) {
      return of(actions.loadIndicatorDataSuccess());
    }
    const actionsToDo = [actions.loadIndicatorData({ indicator: indicator || main.defaultIndicator.id, which })];
    if (main.pools && main.pools.length) {
      // load main data plus pool data
      actionsToDo.push(actions.loadPoolData({ indicator: indicator || main.defaultIndicator.id, which }));
    }
    return of(...actionsToDo);
  });
};

export const poolDataEpic = (action$, store, { of }) => {
  return action$.ofType(types.MAIN_LOAD_POOL_DATA).mergeMap(action => {
    const { geoids, indicator, geography, which } = action.payload;
    const { main } = store.value;
    const { pools } = main;
    const allPoolGeoids = pools.map(p => p.geoid);
    return of(actions.loadIndicatorData({ isPools: true, geoids: geoids || allPoolGeoids, indicator, geography, which }));
  });
};

// gets data for a given indicator, geography, and optional set of geoids (pools)
// except in the case of pools, this will request state-level data in addition
export const indicatorDataEpic = (action$, store, { ajax, of, forkJoin }) => {
  return action$.ofType(types.MAIN_LOAD_INDICATOR_DATA).mergeMap(action => {
    const { main } = store.value;
    const {
      allIndicatorData,
      allPoolData,
      classificationMethod,
      geographiesList,
      rankData,
      currentIndicator,
      currentGeography,
    } = main;

    // note currentIndicator and currentGeography have already been updated in the reducer before now

    if (currentIndicator.layerId) {
      // this is a custom layer. don't do the normal loading
      // we'd arrive here if a custom layer was loaded and we switched geographies or something
      return of(
        actions.loadIndicatorDataSuccess({
          indicator: currentIndicator.id,
          geography: currentGeography.id,
          isPools: action.payload.isPools,
          geographies: null,
        }),
        actions.setClassificationMethod(classificationMethod) // updates choropleth classification if needed)
      );
    }

    // `which` is to specify indicator 1 or 2 in split screen (default 1 when undefined in other modes)
    const { indicator, geography, geoids, isPools, which } = action.payload;
    const state = geographiesList.find(g => g.name === 'State');
    const body = {
      indicator: indicator || main.currentIndicator.id,
      geography: geography || main.currentGeography.id,
      geoids, // <- for pool data
      attributes: ['value', 'percentile'],
    };

    // get the data request functions
    const fetchMainData = () => {
      return {
        body,
        req: ajax({
          url: `/api/v1/indicator/`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        }),
      };
    };
    const fetchStateData = () => {
      const stateBody = {
        indicator: body.indicator,
        geography: state.id,
        attributes: ['value', 'percentile'],
      };
      return {
        body: stateBody,
        req: ajax({
          url: `/api/v1/indicator/`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(stateBody),
        }),
      };
    };

    // check if data already exists for the main geography (e.g. tracts)
    let mainDataLoaded = false;
    const existingData = isPools ? allPoolData : allIndicatorData;
    if (existingData && existingData[body.geography] && existingData[body.geography][body.indicator]) {
      // data already exists
      mainDataLoaded = true;
      if (geoids) {
        // ...but we want specific geoids (probably pools) and their data may not exist yet
        const existingGeoids = existingData[body.geography][body.indicator].map(d => d.geoid);
        const geoidsToRequest = geoids.filter(id => !existingGeoids.includes(id));
        if (geoidsToRequest.length) {
          mainDataLoaded = false;
          body.geoids = geoidsToRequest;
        }
      }
    }

    // check if state-level data exists
    const stateDataLoaded = state && existingData[state.id] && existingData[state.id][body.indicator];

    // request main (e.g. tracts or pools) data and/or state data as needed
    const requests = [];
    if (!mainDataLoaded) requests.push(fetchMainData());
    // also skip state data if this is a pool request
    if (!stateDataLoaded && !isPools) requests.push(fetchStateData());

    // ranks, if any, may need to be updated
    let rankAction = null;
    if (rankData && rankData.data && !isPools) {
      const { data } = rankData;
      if (data.geography !== body.geography || which === 2) {
        // clear rank if swichting geography or entering split screen
        rankAction = actions.getRankData(null);
      } else if (data.indicator !== body.indicator) {
        // if switching indicator, load new ranks
        rankAction = actions.getRankData({
          ...data,
          indicator: body.indicator,
        });
      }
    }

    const actionsToDo = [];

    if (!requests.length) {
      // there's truly no new data to load
      actionsToDo.push(
        actions.loadIndicatorDataSuccess({
          ...body,
          isPools: action.payload.isPools,
          geographies: null,
          which,
        }),
        actions.setClassificationMethod(classificationMethod) // updates choropleth classification if needed)
      );
      if (rankAction) actionsToDo.push(rankAction);
      if (!currentIndicator.Geographies.find(g => g === currentGeography.layer)) {
        const layerToLoad = currentIndicator.Geographies.find(g => g === 'counties') || currentIndicator.Geographies[0];
        const newGeography = main.geographiesList.find(g => g.layer === layerToLoad);
        actionsToDo.push(actions.setGeography(newGeography.id));
      }
      return of(...actionsToDo);
    }

    return forkJoin(...requests.map(r => r.req))
      .mergeMap(res => {
        // data for each geography requested (possibly two, including state level)
        const successData = res.reduce((combined, r, i) => {
          const reqBody = requests[i].body;
          return {
            ...combined,
            [reqBody.geography]: {
              ...reqBody,
              isPools: action.payload.isPools,
              data: r.response.response,
            },
          };
        }, {});
        actionsToDo.push(
          actions.loadIndicatorDataSuccess({
            // send back the indicator/geog actually requested (e.g. tracts) for clarity
            ...body,
            geographies: successData, // contains the actual data (state and/or e.g. tracts)
            isPools: action.payload.isPools,
            which,
          }),
          actions.setClassificationMethod(classificationMethod) // updates choropleth classification if needed)
        );
        if (rankAction) actionsToDo.push(rankAction);
        // below, if the indicator does not have data for the current geography, switch geographies
        // (would be better to do this somehow BEFORE loading data, but at least the data would be empty/small)
        // only do this if geography wasn't explicitly specified (i.e. this didn't occur via setGeography)
        if (!geography && !currentIndicator.Geographies.find(g => g === currentGeography.layer)) {
          // prefer county level if it exists
          const layerToLoad = currentIndicator.Geographies.find(g => g === 'counties') || currentIndicator.Geographies[0];
          const newGeography = main.geographiesList.find(g => g.layer === layerToLoad);
          actionsToDo.push(actions.setGeography(newGeography.id));
        }
        return of(...actionsToDo);
      })
      .catch(error => of(actions.testError(error)));
  });
};

export const histogramEpic = (action$, store, { ajax, of }) => {
  return action$.ofType(types.MAIN_LOAD_HISTOGRAM_DATA).mergeMap(action => {
    const body = action.payload;
    // fetch data
    return ajax({
      url: `/api/v1/histogram/`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .map(response => {
        return actions.histogramDataSuccess({
          ...body,
          data: response.response.response,
        });
      })
      .catch(error => of(actions.testError(error)));
  });
};

export const classificationEpic = (action$, store, { of }) => {
  return action$.ofType(types.MAIN_SET_CLASSIFICATION_METHOD).mergeMap(action => {
    const method = action.payload;
    const { main } = store.value;
    const { currentIndicatorData, choroplethClassification, currentIndicatorData2, choroplethClassification2 } = main;

    const stateData = getCurrentStateData(main);
    const { breakVals, attribute } = getClassificationData(currentIndicatorData, stateData, method, main.attribute);
    const newClassification = choroplethClassification.map((c, i) => {
      return {
        ...c,
        min: breakVals[i][0],
        max: breakVals[i][1],
      };
    });

    const actionsToDo = [
      actions.setClassification({ classification: newClassification, which: 1 }),
      actions.setAttribute(attribute),
      actions.setChoroplethFilter(null),
    ];

    if (currentIndicatorData2 && currentIndicatorData2.length) {
      const stateData2 = getCurrentStateData(main, 2);
      const breakVals2 = getClassificationData(currentIndicatorData2, stateData2, method, main.attribute).breakVals;
      const newClassification2 = choroplethClassification2.map((c, i) => {
        return {
          ...c,
          min: breakVals2[i][0],
          max: breakVals2[i][1],
        };
      });
      actionsToDo.push(actions.setClassification({ classification: newClassification2, which: 2 }));
    }

    return of(...actionsToDo);
  });
};

/**
 * Feature data
 */

export const featureConditionsEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_LOAD_FEATURE_CONDITION_DATA)
    .mergeMap(action => {
      const { main } = store.value;
      const { geoid } = action.payload;

      // fetch feature data
      const body = {
        geography: main.currentGeography.id,
        geoid,
        attributes: ['percentile', 'value', 'numerator'],
        indicator: main.indicatorList
          .filter(c => c.name !== 'Race/Ethnicity')
          .reduce((flat, group) => [...flat, ...group.Indicators], [])
          .map(i => i.id),
      };
      return ajax({
        url: `/api/v1/conditions/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .mergeMap(response => {
          return of(actions.featureConditionDataSuccess({ geoid, data: response.response.response }));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const featureMetadataEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_LOAD_FEATURE_METADATA)
    .mergeMap(action => {
      const { main } = store.value;
      const { geoid } = action.payload;
      const indicator = main.currentIndicator.id;

      // fetch feature data
      const body = {
        geography: main.currentGeography.id,
        indicator: main.indicatorList.reduce((flat, group) => [...flat, ...group.Indicators], []).map(i => i.id),
        geoid,
        attributes: ['value', 'percentile'],
      };
      return ajax({
        url: `/api/v1/feature/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .mergeMap(response => {
          return of(actions.featureMetadataSuccess({ geoid, indicator, data: response.response.response }));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const featureRaceDataEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_LOAD_RACE_DATA)
    .mergeMap(action => {
      const { main } = store.value;
      const { geoid } = action.payload;

      // fetch feature data
      const body = {
        geography: main.currentGeography.id,
        geoid,
        attributes: ['value', 'numerator', 'denominator'],
        indicator: main.indicatorList.filter(c => c.name === 'Race/Ethnicity')[0].Indicators.map(i => i.id),
      };
      return ajax({
        url: `/api/v1/conditions/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .mergeMap(response => {
          return of(actions.raceDataSuccess({ geoid, data: response.response.response }));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

/**
 * Pooling
 */

export const createPoolEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_CREATE_POOL)
    .mergeMap(action => {
      const { main } = store.value;
      const { currentGeography, currentIndicator } = main;

      // fetch feature data
      const body = {
        geography: currentGeography.id,
        indicator: currentIndicator.id,
      };
      if (action.payload.poolId) {
        body.geoid = action.payload.poolId;
      } else if (action.payload.geom) {
        body.geom = action.payload.geom;
      } else {
        body.geoids = [Object.keys(action.payload)];
      }

      return ajax({
        url: `/api/v1/pool/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .mergeMap(response => {
          let entities;
          const geojson = response.response.response;
          const feature = geojson.features[0];
          if (action.payload.geom) {
            // pool from polygon, need to create entities object

            entities = feature.properties.geoids.reduce((ents, geoid, i) => {
              return {
                ...ents,
                [geoid]: feature.properties.names[i],
              };
            }, {});
          } else {
            entities = action.payload;
          }

          if (feature.properties.percentile !== undefined) {
            // update pool data if we received any
            return of(
              actions.poolSuccess({ entities, geojson }),
              actions.loadIndicatorDataSuccess({
                geography: currentGeography.id,
                indicator: currentIndicator.id,
                isPools: true,
                geographies: {
                  [currentGeography.id]: {
                    geography: currentGeography.id,
                    indicator: currentIndicator.id,
                    data: [feature.properties],
                  },
                },
              })
            );
          }
          return of(actions.poolSuccess({ entities, geojson }));
        })
        .catch(error => {
          if (action.payload.callback) action.payload.callback(error);
          return of(actions.testError(error));
        });
    })
    .catch(() => []);
};

/**
 * rank
 */

export const rankEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_GET_RANK_DATA)
    .mergeMap(action => {
      const { main } = store.value;
      const { currentGeography, classificationMethod } = main;
      if (!action.payload) {
        // classification may need to be reset if the rank data had been rescaled
        return of(actions.rankDataSuccess(null), actions.setClassificationMethod(classificationMethod));
      }

      return ajax({
        url: `/api/v1/rank/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload),
      })
        .mergeMap(response => {
          const actionsToDo = [actions.rankDataSuccess({ data: action.payload, ranks: response.response.response })];
          if (action.payload.geography !== currentGeography.id) {
            // rank options can require a change in geography
            actionsToDo.unshift(actions.setGeography(action.payload.geography));
          } else {
            // classification may need to be reset if the rank data is or previously was rescaled
            // if geog changed (above), this will still happen via that action
            actionsToDo.push(actions.setClassificationMethod(classificationMethod));
          }
          return of(...actionsToDo);
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

/**
 * login
 */

export const loginEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_LOGIN)
    .mergeMap(action => {
      return ajax({
        url: `/login`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload),
      })
        .mergeMap(response => {
          if (response.response.responseCode !== 200) {
            return of(actions.setLoginError(response.response.responseMessage));
          }
          return of(actions.loginSuccess({ username: action.payload.email }), actions.getSavedViews(), actions.getLayers());
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const logoutEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_LOGOUT)
    .mergeMap(() => {
      return ajax({
        url: `/logout`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .mergeMap(() => {
          return of(actions.logoutSuccess());
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

/**
 * saved views
 */

export const getSavedViewsEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_GET_VIEWS)
    .mergeMap(() => {
      const { main } = store.value;
      const { isLoggedIn } = main;
      return ajax({
        url: `/api/v1/views`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .mergeMap(response => {
          if (response.response.responseCode !== 200) {
            return of(actions.receivedViews([]));
          }
          if (!isLoggedIn) {
            return of(
              actions.loginSuccess({ username: response.response.response.user }),
              actions.receivedViews(response.response.response.views)
            );
          }
          return of(actions.receivedViews(response.response.response.views));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const saveViewEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_SAVE_VIEW)
    .mergeMap(action => {
      const { main, mapcontainer } = store.value;
      const { currentIndicator, currentGeography, pools, savedViews } = main;
      const { viewport } = mapcontainer;
      const { latitude, longitude, zoom } = viewport;
      const poolIds = pools.map(p => p.geoid);
      const body = {
        title: action.payload,
        latitude,
        longitude,
        zoom,
        indicator: currentIndicator.id,
        geography: currentGeography.id,
        pools: poolIds,
      };
      return ajax({
        url: `/api/v1/views`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .mergeMap(response => {
          const existingViews = [...savedViews];
          existingViews.unshift(response.response.response);
          return of(actions.receivedViews(existingViews));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const deleteViewEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_DELETE_VIEW)
    .mergeMap(action => {
      const { main } = store.value;
      const { savedViews } = main;
      return ajax({
        url: `/api/v1/views`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: action.payload }),
      })
        .mergeMap(() => {
          const remainingViews = savedViews.filter(v => v.id !== action.payload);
          return of(actions.receivedViews(remainingViews));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const loadSavedViewEpic = (action$, store, { of }) => {
  return action$
    .ofType(types.MAIN_LOAD_SAVED_VIEW)
    .mergeMap(action => {
      const { latitude, longitude, zoom } = action.payload;

      const { geography /* ,  pools */ } = action.payload;
      const actionsToDo = [
        actions.setGeography(geography),
        // imported action from mapcontainer... probably bad and should just be moved into main
        setViewport({
          latitude,
          longitude,
          zoom,
        }),
      ];
      // TO DO: load pools something like this
      // if (pools) {
      //   actionsToDo.push(...pools.map(pool => actions.createPool({ poolId: pool })));
      // }
      return of(...actionsToDo);
    })
    .catch(() => []);
};

export const getViewByIdEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_GET_VIEW_BY_ID)
    .mergeMap(action => {
      const { main } = store.value;
      const { currentGeography } = main;

      return ajax({
        url: `/api/v1/view/${action.payload}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .mergeMap(response => {
          const savedView = response.response.response;
          if (savedView) {
            return of(actions.loadSavedView(savedView));
          }
          return of(actions.setGeography(currentGeography.id));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

/**
 * import
 */

export const getLayersEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_GET_LAYERS)
    .mergeMap(() => {
      return ajax({
        url: `/api/v1/layers`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .mergeMap(response => {
          if (response.response.responseCode !== 200) {
            return of(actions.layersSuccess([]));
          }
          const layers = response.response.response.map(l => ({
            ...l,
            id: parseInt(`99999${l.id}`, 10), // hopefully ensures this id is different from regular indicators
            layerId: l.id, // <- layerId will be the one in the database, and should be used in any API methods
          }));
          return of(actions.layersSuccess(layers));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const uploadEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_UPLOAD_FILE)
    .mergeMap(action => {
      return ajax({
        url: `/api/v1/layers/`,
        method: 'POST',
        body: action.payload, // FormData
      })
        .mergeMap(response => {
          return of(actions.uploadSuccess(response.response.response));
        })
        .catch(error => {
          console.log(error); // where is the response text??
          return of(
            actions.uploadError({
              type: 'upload',
              errors: [error],
            })
          );
        });
    })
    .catch(() => []);
};

export const saveLayerEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_SAVE_LAYER)
    .mergeMap(action => {
      return ajax({
        url: `/api/v1/save/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload),
      })
        .mergeMap(response => {
          const actualResponse = response.response.response;
          const status = response.response.responseCode;
          if (status !== 200) {
            return of(
              actions.saveLayerError({
                type: 'import',
                errors: [response.response.responseMessage],
              })
            );
          }
          if (actualResponse.errors && actualResponse.errors.length) {
            // assumes any 'errors' array means geography errors, which prob isn't great to assume
            return of(
              actions.saveLayerError({
                type: 'geometry',
                errors: actualResponse.errors,
              })
            );
          }
          return of(actions.saveLayerSuccess(actualResponse), actions.getLayers());
        })
        .catch(error => {
          console.log(error);
          return of(actions.uploadError(error));
        });
    })
    .catch(() => []);
};

export const deleteLayerEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_DELETE_LAYER)
    .mergeMap(action => {
      return ajax({
        url: `/api/v1/layers`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: action.payload }),
      })
        .mergeMap(() => {
          return of(actions.getLayers());
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const layerDataEpic = (action$, store, { of, getJSON }) => {
  return action$
    .ofType(types.MAIN_LOAD_LAYER_DATA)
    .mergeMap(action => {
      const { type, id, layerId } = action.payload;
      if (type === 'csv') {
        return getJSON(`/api/v1/indicator/layer/${layerId}`)
          .mergeMap(res => {
            const { response } = res;
            // data for each geography requested (possibly two, including state level)
            const successData = {
              [response.geography]: {
                indicator: id,
                geography: response.geography,
                data: response.data,
              },
            };
            const actionsToDo = [];
            actionsToDo.push(
              actions.loadIndicatorDataSuccess({
                // send back the indicator/geog actually requested (e.g. tracts) for clarity
                indicator: id,
                geography: response.geography,
                geographies: successData, // contains the actual data (state and/or e.g. tracts)
              })
            );
            const { main } = store.value;
            const { classificationMethod } = main;
            let newClassification = classificationMethod;
            if (newClassification.toLowerCase() === 'quartiles') newClassification = 'Equal Interval';
            actionsToDo.push(actions.setClassificationMethod(newClassification)); // updates choropleth classification if needed)
            // clear geojson layer. not strictly necessary but UI would need work to support two custom layers
            actionsToDo.push(actions.setOverlayLayer(null));
            return of(...actionsToDo);
          })
          .catch(error => of(actions.testError(error)));
      }
      // geojson/shapefile layer
      return getJSON(`/api/v1/layer/${layerId}`)
        .mergeMap(res => {
          const { main } = store.value;
          const { currentIndicator } = main;
          const actionsToDo = [actions.setOverlayLayer(res)];
          if (currentIndicator.layerId) {
            // clear csv layer. not strictly necessary but UI would need work to support two custom layers
            actionsToDo.push(actions.setIndicator({ indicator: null }));
          }
          return of(...actionsToDo);
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

/**
 * export
 */

export const mapExportEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_EXPORT_MAP)
    .mergeMap(action => {
      // the html canvas (maybe? it was removed from DOM), png dataURL, and blob format are available here
      // const { canvas, dataURL, blob } = action.payload;
      const { dataURL } = action.payload;

      // TO DO: replace below with correct endpoint and header or body or whatever, to send image
      return ajax({
        url: `/api/v1/exporter`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          imageData: dataURL.split(',')[1],
        },
        responseType: 'blob',
      })
        .mergeMap(({ response }) => {
          console.log(response);
          const blob = new Blob([response], { type: 'application/zip' });
          console.log(blob);
          const objectUrl = URL.createObjectURL(response);
          window.open(objectUrl);
          return of(actions.exportSuccess());
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const policiesEpic = (action$, store, { of, getJSON, ajax }) => {
  return action$
    .ofType(types.MAIN_GET_POLICIES)
    .mergeMap(action => {
      if (!action.payload) {
        // default, no feature selected
        const { main } = store.value;
        if (main.defaultPolicies) return of(actions.policiesSuccess({ policies: main.defaultPolicies }));
        return getJSON(`/api/v1/policy`)
          .mergeMap(res => {
            const { response } = res;
            return of(actions.policiesSuccess({ policies: response }));
          })
          .catch(error => {
            return of(actions.testError(error));
          });
      }
      return ajax({
        url: `/api/v1/policy`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.payload),
      })
        .mergeMap(response => {
          const policies = response.response.response;
          return of(actions.policiesSuccess({ ...action.payload, policies }));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const customScoreEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_SET_CUSTOM_SCORE)
    .mergeMap(action => {
      const { main } = store.value;
      const { indicatorList, currentGeography, classificationMethod, allIndicatorData } = main;
      const hpiIndicators = indicatorList
        .filter(d => d.weight > 0)
        .reduce((flat, domain) => [...flat, ...domain.Indicators], [])
        .map(i => i.id);
      // below, if null payload or custom score is actually the same as default HPI score
      if (
        !action.payload ||
        (!action.payload.domains.length && hpiIndicators.every(i => action.payload.indicators.indexOf(i) !== -1))
      ) {
        // classification may need to be reset if the rank data had been rescaled
        return of(
          actions.customScoreSuccess(null),
          actions.setClassificationMethod('Quartiles'),
          actions.setGeography(currentGeography.id) // geog may have changed; this will load data for default indicator if needed
        );
      }
      const domains = indicatorList
        .filter(domain => domain.weight)
        .reduce((domainWeights, domain) => {
          const customWeight = action.payload.domains.find(d => d.domain === domain.id);
          const weight = { domain: domain.id, weight: customWeight ? customWeight.weight : domain.weight };
          return [...domainWeights, weight];
        }, {});
      // unique id for this set of domain weights and indicators
      const id = domains
        .reduce((concatenated, d) => concatenated.concat(d.domain.toString()).concat(d.weight.toString()), '')
        .concat(action.payload.indicators.reduce((concatenated, d) => concatenated.concat(d.toString()), ''));
      if (allIndicatorData[currentGeography.id][id]) {
        return of(actions.customScoreSuccess({ id, data: null }), actions.setClassificationMethod(classificationMethod));
      }
      return ajax({
        url: `/api/v1/custom/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...action.payload,
          geography: currentGeography.id,
          domains,
        }),
      })
        .mergeMap(response => {
          return of(
            actions.customScoreSuccess({ id, data: response.response.response }),
            actions.setClassificationMethod(classificationMethod)
          );
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};

export const vulnerabilitiesEpic = (action$, store, { ajax, of }) => {
  return action$
    .ofType(types.MAIN_SET_MULTIPLE_VULNERABILITIES)
    .mergeMap(action => {
      const { main } = store.value;
      const { currentGeography, defaultIndicator, defaultClassification } = main;

      if (!action.payload) {
        return of(actions.setIndicator(defaultIndicator.id), actions.setClassificationMethod(defaultClassification));
      }

      return ajax({
        url: `/api/v1/vulnerability/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          geography: currentGeography.id,
          indicator: action.payload,
        }),
      })
        .mergeMap(response => {
          console.log(response);
          return of(actions.multipleVulnerabilitiesSuccess(response.response.response));
        })
        .catch(error => of(actions.testError(error)));
    })
    .catch(() => []);
};
