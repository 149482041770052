const panelsToInclude = [
  'community-conditions',
  'covid',
  'view-indicators',
  // ... list all included panels by key or title (see panelsConfig below for valid options)
];

// the default panel(s) to open if a map feature is selected (both are optional)
export const defaultPanel = {
  // show this one if the default indicator (HPI score?) is selected
  defaultIndicatorSelected: 'covid',
  // show this one if a different indicator is selected
  otherIndicatorSelected: 'covid',
};

// defualt indicator, geography, and classification method
export const defaultIndicatorName = 'County COVID-19 Cases';
export const defaultGeographyName = 'Counties';
export const defaultClassification = 'Equal Interval'; // quartiles, equal interval, or natural breaks

// for non-percentile indicators, e.g. climate or covid data
export const sequentialColors = ['#EFF3FF', '#BDD7E7', '#6BAED6', '#2171B5'];
// for percentiles where higher = healither. the green-blue hpi colors for example
export const divergingColors = ['rgb(33, 113, 181)', 'rgb(158, 202, 225)', 'rgb(166, 219, 160)', 'rgb(0, 136, 55)'];
export const percentileColors = divergingColors;
export const valueColors = sequentialColors;
export const vulnerabilityColors = sequentialColors;

// panel titles, colors, descriptions
const panelsConfig = [
  {
    title: 'Community Conditions', // title that appears in the panel itself
    category: 'Explore',
    buttonColor: '#E6EFFF', // button background color (lighter)
    color: '#3562AB', // text and border color (darker)
    buttonLabel: 'Get Community Conditions', // label on button in main menu
    description: 'Explore the California Healthy Places Index score and neighborhood measures linked to health.',
    key: 'community-conditions', // DO NOT CHANGE 'key' value
  },
  {
    title: 'COVID-19 Impacts',
    category: 'Explore',
    buttonColor: '#f2cfce',
    color: '#993d3a',
    buttonLabel: 'COVID-19 Impacts',
    description: 'View key measures of COVID-19, including cases, deaths, and deaths by race/ethnicity.',
    key: 'covid', // DO NOT CHANGE
  },
  {
    title: 'Filter Indicators',
    category: 'Explore',
    buttonColor: '#EEFAE1',
    color: '#38610A',
    buttonLabel: 'Filter Indicators',
    description: 'Filter entities on the map.',
    key: 'filter', // DO NOT CHANGE
  },
  {
    title: 'View Indicators',
    category: 'View data',
    buttonColor: '#F0EBFF',
    color: '#6C51B5',
    buttonLabel: 'View Indicators',
    description: 'Select and view additional indicators on the map.',
    key: 'view-indicators', // DO NOT CHANGE
  },
  {
    title: 'Predict Life Expectancy',
    view: 'lifeexpectancy',
    category: 'Explore',
    buttonColor: '#FFE6E6',
    color: '#DE4E4E',
    buttonLabel: 'Predict Life Expectancy',
    key: 'predict-life-expectancy', // DO NOT CHANGE
  },
  {
    title: 'Pool Geographies',
    category: 'Organize',
    buttonColor: '#FBF0FC',
    color: '#B14EBA',
    buttonLabel: 'Pool Geographies',
    key: 'pool-geographies', // DO NOT CHANGE
  },
  {
    title: 'Edit Display',
    category: 'Customize',
    buttonColor: '#E1FAFA',
    color: '#31A3A3',
    buttonLabel: 'Edit Display',
    key: 'edit-display', // DO NOT CHANGE
  },
  {
    title: 'Compare Data',
    category: 'View data',
    buttonColor: '#F0FCFF',
    color: '#24A0BF',
    buttonLabel: 'Compare Data',
    key: 'compare-indicators', // DO NOT CHANGE
  },
  {
    title: 'Rank By Geography',
    category: 'Organize',
    buttonColor: '#E1FAE2',
    color: '#128216',
    buttonLabel: 'Rank Geographies',
    key: 'rank-by-geography', // DO NOT CHANGE
  },
  {
    title: 'Upload Data',
    category: 'View data',
    buttonColor: '#FFF4E6',
    color: '#B36C09',
    buttonLabel: 'Upload Data',
    key: 'upload-data', // DO NOT CHANGE
  },
  {
    title: 'Policy Opportunities',
    category: 'Explore',
    buttonColor: '#F0F2D0',
    color: '#7B7D5F',
    buttonLabel: 'Policy Opportunities',
    key: 'policy-opportunities', // DO NOT CHANGE
  },
  {
    title: 'Create Custom Score',
    category: 'Customize',
    buttonColor: '#FAF9DE',
    color: '#ABA533',
    buttonLabel: 'Create Custom Score',
    key: 'custom-score', // DO NOT CHANGE
  },
];

export const panelData = panelsConfig.filter(p => panelsToInclude.includes(p.key) || panelsToInclude.includes(p.title));
