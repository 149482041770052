//  map-container.reducer.js
//  Project hpi
//
//  Created by Axis Maps on 4/7/2020, 9:30:49 AM
//

import persistReducer from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import * as types from './map-container.actionType';

const persistConfig = {
  key: 'MapContainer',
  storage,
  whitelist: [
    /* keys to be persisted */
  ],
};

const initialState = {
  mapBounds: {
    bounds: [
      [-124.409591, 32.534156],
      [-114.131211, 42.009518],
    ],
    zoom: 6,
  },
  viewport: {
    latitude: 37.42147639398113,
    longitude: -119.27040100000272,
    zoom: 5,
  },
  featureGeoJSON: {}, // featureGeoJSON[geography][geoid] = a FeatureCollection
  probeData: null,
  rankedFeatureSourceData: { type: 'FeatureCollection', features: [] },
  selectedFeatureSourceData: { type: 'FeatureCollection', features: [] },
};

function MapContainer(state = initialState, action) {
  // NOSONAR
  switch (action.type) {  // eslint-disable-line
    case types.MAPCONTAINER_SET_BOUNDS: {
      return {
        ...state,
        mapBounds: action.payload,
      };
    }

    case types.MAPCONTAINER_SET_VIEWPORT: {
      return {
        ...state,
        viewport: {
          ...state.viewport,
          ...action.payload,
        },
      };
    }

    case types.MAPCONTAINER_SET_SELECTED_FEATURE_SOURCE_DATA: {
      return {
        ...state,
        selectedFeatureSourceData: { type: 'FeatureCollection', features: action.payload || [] },
      };
    }

    case types.MAPCONTAINER_SET_RANK_FEATURE_SOURCE_DATA: {
      return {
        ...state,
        rankedFeatureSourceData: { type: 'FeatureCollection', features: action.payload || [] },
      };
    }

    case types.MAPCONTAINER_GEOJSON_SUCCESS: {
      const geojsons = { ...state.featureGeoJSON };
      const { geoid, geography, data, type } = action.payload;
      if (!geojsons[geography]) geojsons[geography] = {};
      const featureArray = data ? data.features || [] : [];
      geojsons[geography][geoid] = featureArray;
      if (type === 'selected') {
        return {
          ...state,
          featureGeoJSON: geojsons,
          selectedFeatureSourceData: { type: 'FeatureCollection', features: featureArray },
        };
      }
      if (type === 'rank') {
        return {
          ...state,
          featureGeoJSON: geojsons,
          rankedFeatureSourceData: { type: 'FeatureCollection', features: featureArray },
        };
      }
      return {
        ...state,
        featureGeoJSON: geojsons,
      };
    }

    case types.MAPCONTAINER_SET_PROBE_DATA: {
      return {
        ...state,
        probeData: action.payload,
      };
    }

    case types.MAPCONTAINER_TEST:
      return {
        ...state,
        loading: true,
      };

    case types.MAPCONTAINER_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };

    default:
      return state;
  }
}

export default persistReducer(persistConfig, MapContainer);
