//  map-container.action.js
//  Project hpi
//
//  Created by Axis Maps on 4/7/2020, 9:30:49 AM
//

import * as types from './map-container.actionType';

export function test(payload) {
  return {
    type: types.MAPCONTAINER_TEST,
    payload,
  };
}

export function testSuccess(payload) {
  return {
    type: types.MAPCONTAINER_TEST_SUCCESS,
    payload,
  };
}

export function setViewport(payload) {
  return {
    type: types.MAPCONTAINER_SET_VIEWPORT,
    payload,
  };
}

export function setBounds(payload) {
  return {
    type: types.MAPCONTAINER_SET_BOUNDS,
    payload,
  };
}

export function getGeoJSON(payload) {
  return {
    type: types.MAPCONTAINER_GET_GEOJSON,
    payload,
  };
}

export function geojsonSuccess(payload) {
  return {
    type: types.MAPCONTAINER_GEOJSON_SUCCESS,
    payload,
  };
}

export function setMapProbeData(payload) {
  return {
    type: types.MAPCONTAINER_SET_PROBE_DATA,
    payload,
  };
}

export function setSelectedFeatureSourceData(payload) {
  return {
    type: types.MAPCONTAINER_SET_SELECTED_FEATURE_SOURCE_DATA,
    payload,
  };
}

export function setRankFeatureSourceData(payload) {
  return {
    type: types.MAPCONTAINER_SET_RANK_FEATURE_SOURCE_DATA,
    payload,
  };
}
